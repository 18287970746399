@import 'newreset.css';
@import 'fonts.css';

body {
  height: 100%;
  font-family: 'Lexend Deca', sans-serif;
  /* 1rem */
  font-size: 18px;
}

/* Border-box boilerplate that still allows padding-box and content-box */
html {
  height: 100%;
  box-sizing: border-box;
}

* {
  user-select: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#root,
.bgio-client {
  height: 100%;
}

button,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lexend Deca', sans-serif;
}
